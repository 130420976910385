@font-face {
  font-family: "NeuzeitGro";
  font-weight: 400;
  src: url('./fonts/NeuzeitGro-Reg.ttf');
}

@font-face {
  font-family: "NeuzeitGro";
  font-weight: 800;
  src: url('./fonts/NeuzeitGro-Bol.ttf');
}

.App {
  text-align: center;
  /* height: 100vh; Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #242424;
}

.desktop-facade {
  pointer-events: none;
  background: rgba(0,0,0,0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.story-offset {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.segment-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.segment-outer {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  aspect-ratio: 0.5625;
  margin: auto;
  transition: 0.7s cubic-bezier(0.56, 0, 0.34, 1);
}

.segment-inner {
  pointer-events: auto;
  margin: 20px;
  border-radius: 8px;
  overflow: hidden;
  background: rgb(17, 17, 17);
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 0.5625;
  filter: drop-shadow(0 20px 30px rgba(0,0,0,0.3));
  color:#fff;
  font-size: 50px;
}

.segment-inner video {
  width: 1080px !important;
  height: 1920px !important;
}

.story-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.title-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998
}

.nav-btn-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.prev-btn,
.next-btn {
  pointer-events: auto;
  border: none;
  background: #fff;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  font-size: 1.2em;
  padding: 5px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0.5;
}

.story-next {
  position:absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.story-prev {
  position:absolute;
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;
  z-index: 1000;
}

.prev-btn:hover,
.next-btn:hover {
  opacity: 0.7;
}

.prev-btn {
  left: -30px;
}

.next-btn {
  right: -30px;
}

.pdj-msg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30,30,30, 0.7);
  z-index: 1000;
  color: #fff;
  font-family: "NeuzeitGro";
  font-size: 1.2em;
  padding: 50px 20%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.pdj-text {
  text-align: left;
  width: 35%;
  min-width: 800px;
  margin: auto;
  margin-top: 100px;
  padding: 50px;
  box-sizing: border-box;
  flex: 2;
}

.popout-text {
  padding: 20px;
  margin: 0 -15px;
  background: #fbd42c;
  color: #000;
  /* border-radius: 4px; */

}

.continue-btn__wrapper {
  width: 100%;
  /* text-align: center; */
}

.continue-btn {
  padding: 10px 20px 8px 20px;
  font-size: 1.2em;
  background: #2a12ff;
  font-weight: 800;
  font-family: "NeuzeitGro";
  color: #fff;
  border: none;
  /* border-radius: 4px; */
}

.continue-btn:hover {
  background: #76bfff;

}

.landing-logo {
  /* position: absolute; */
  width: 5em;
  top: 10%;
  left: 10%;
}

.landing-bg {
  background-image: url('./images/landing-bg.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pdj-mdj-portrait {
  background-image: url('./images/pdj+mdj_portrait.png');
  background-size: cover;
  background-position: center;
  margin-top: 150px;
  height: 50%;
  min-width: 400px;
  min-height: 500px;
  flex: 1;
}

@media only screen and (max-width: 1000px) {
  .App {
    background: #000;
  }

  .stories-inner {
    /* margin: 0; */
  }

  .nav-btn-container {
    display: none;
  }

  .desktop-facade {
    display: none;
  }

  .pdj-text {
    width: inherit;
    margin: 40px;
    min-width: 400px;
    flex: 2 1 !important;
  }
}

@media only screen and (max-width: 1480px) {
  .pdj-mdj-portrait {
    display: none;
  }

  .pdj-msg {
    padding: 0;
  }

  .pdj-text {
    flex: none;
  }
}